'use client';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { IconButton, Typography, Stack } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import theme from '@styles/muiTheme';
import { Trans } from "@lingui/macro";

export type QuantityInputProps = {
  qty: number;
  setAllowSave: React.Dispatch<React.SetStateAction<boolean>>;
  setPressedEnter: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.RefObject<HTMLInputElement>; // Accept the ref from the parent
};

export const QuantityInput = ({ qty, setAllowSave, setPressedEnter, inputRef }: QuantityInputProps) => {
  const [value, setValue] = useState('');
  const missingPcs = qty - parseInt(value, 10);

  useEffect(() => {
    if (missingPcs == 0) {
      setAllowSave(true);
    } else {
      setAllowSave(false);
    }
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = parseInt(value, 10);
    if (e.key === 'Enter') {
      if (newValue == qty) {
        setPressedEnter(true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const incrementValue = () => {
    const newValue = parseInt(value, 10) + 1;
    if (newValue > qty) {
      setValue('0');
    } else if (!isNaN(newValue) && newValue <= qty) {
      setValue(String(newValue));
    } else {
      setValue('0');
    }
  };

  const decrementValue = () => {
    const newValue = parseInt(value, 10) - 1;
    if (!isNaN(newValue) && newValue >= 0) {
      setValue(String(newValue));
    } else {
      setValue(String(0));
    }
  };

  return (
    <Stack>
      <div className={styles.quantityInput}>
        <IconButton color="secondary" onClick={decrementValue}>
          <ArrowBackIosRoundedIcon fontSize="large" />
        </IconButton>
        <input
          type="number"
          ref={inputRef} // Assign the ref here
          max={999}
          min={0}
          placeholder="0"
          value={value}
          onChange={handleInputChange}
          maxLength={3}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onKeyDown={handleKeyDown}
          style={{
            margin: '0.4rem auto',
            WebkitAppearance: 'none',
            MozAppearance: 'textfield',
            color: theme.palette.error.main,
            width: `${3 + value.length}rem`,
          }}
        />
        <IconButton color="secondary" onClick={incrementValue}>
          <ArrowForwardIosRoundedIcon fontSize="large" />
        </IconButton>
      </div>
      {missingPcs != 0 && value != '' && value != '0' && (
        <Typography align="center" color={theme.palette.primary.contrastText} variant="subtitle2">
          <Trans>quantityInputFail</Trans>: {qty - parseInt(value, 10)}
        </Typography>
      )}
    </Stack>
  );
};
